<template>
  <section class="my-5">
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-autocomplete
          :items="instituciones"
          item-value="id"
          item-text="nombre"
          clearable
          outlined
          label="Instituciones"
          v-model="institucion"
          @change="getUnidades(institucion), getSolicitudesCompraFilter()"
          @click:clear="getSolicitudesCompra"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-autocomplete
          :items="unidades"
          item-value="id"
          item-text="nombre"
          clearable
          outlined
          label="Unidad"
          v-model="unidad"
          @change="getSolicitudesCompraFilter"
          @click:clear="getSolicitudesCompra"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-autocomplete
          :items="tipo_compras"
          item-value="id"
          item-text="nombre"
          clearable
          outlined
          label="Método de compra"
          v-model="tipo_compra"
          @change="getSolicitudesCompraFilter"
          @click:clear="getSolicitudesCompra"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-menu
          ref="rangoFechas"
          v-model="menuFechas"
          :close-on-content-click="false"
          :return-value.sync="fechas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            outlined
              :value="rangoFechasTexto"
              label="Desde - Hasta"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="
                ( filters.fecha_desde= null), (filters.fecha_hasta= null ),
                getSolicitudesCompra()
              "
            ></v-text-field>
          </template>
          <v-date-picker
          title="es"
            v-model="fechas"
            range
            locale="es-Es"
            @click:date="checkRange"
            :max="fechaActual"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="(menuFechas = false), (fechas = [])"
            >
              Cancelar
            </v-btn>
            <v-btn
              :loading="deshabilitarBoton"
              :disabled="deshabilitarBoton"
              text
              color="primary"
              @click="$refs.rangoFechas.save(fechas), getSolicitudesCompraFilter()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-select
          :items="estados"
          item-value="id"
          item-text="nombre"
          clearable
          v-model="estado"
          label="Estado"
          outlined
          @change="getSolicitudesCompraFilter"
          @click:clear="getSolicitudesCompra()"
        ></v-select>
      </v-col>
    </v-row>
    <p class="text-h6 font-museo-sans primary--text">Listado de solicitudes</p>
    <data-table-component
      :headers="encabezados"
      :items="solicitudesCompra"
      :total_registros="filters.total_rows"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      @paginar="paginar"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ moment(item.created_at).format("DD/MM/YYYY hh:mm A") }}
      </template>
      <template v-slot:[`item.paso.nombre`]="{ item }">
        <v-chip
          label
          :dark="
            item.paso?.nombre.includes('Cancelado') ||
            item.paso?.nombre.includes('Aprobado por')
          "
          :color="item.paso?.color_estado"
        >
          {{ item.paso?.nombre || "" }}
        </v-chip>
      </template>
      <template #[`item.accion`]="{ item }">
        <v-row class="ma-0 pa-0 pl-5" style="width: 80%">
          <v-col class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :to="{ path: `/solicitudes-compra/${item.id}/detalle/${item.id_tipo_solicitud}` }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </data-table-component>
  </section>
</template>

<script>
import moment from "moment-timezone";
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  name: "SolicitudesCompraView",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      institucion: null,
      tipo_compra: null,
      unidad: null,
      estado: null,
      instituciones: [],
      unidades: [],
      tipo_compras: [],
      estados: [],
      menuFechas: false,
      fechas: ["", ""],
      deshabilitarBoton: false,
      fechaActual: moment().format("YYYY-MM-DD"),
      encabezados: [
        {
          text: "Correlativo",
          value: "codigo",
          align: "center",
        },
        {
          text: "Institución",
          value: "institucion",
          align: "center",
        },
        {
          text: "Unidad solicitante",
          value: "unidad",
          align: "center",
        },
        {
          text: "Fecha de creación",
          value: "created_at",
          align: "center",
        },
        {
          text: "Método de compra",
          value: "tipo_solicitud",
          align: "center",
        },
        {
          text: "Estado",
          value: "paso.nombre",
          align: "center",
        },
        {
          text: "Acción",
          value: "accion",
          align: "center",
          width: "15em",
          sortable: false,
        },
      ],
      solicitudesCompra: [],
      filters: {
        page: 1,
        per_page: 10,
        pagination: true,
        total_rows: null,
        fecha_desde: null,
        fecha_hasta: null,
        id_institucion: null,
        id_unidad: null,
        id_estado: null,
        id_tipo_solicitud: null,
      },
    };
  },
  computed: {
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    rangoFechasTexto() {
      // const fechaDesde = this.fechas[0];
      // const fechaHasta = this.fechas[1];
      if (
        this.filters.fecha_desde == null &&
        this.filters.fecha_hasta == null
      ) {
        return "";
      } else {
        return (
          moment(this.filters.fecha_desde).format("DD/MM/YYYY") +
          " - " +
          moment(this.filters.fecha_hasta).format("DD/MM/YYYY")
        );
      }
    },
  },
  methods: {
    async getInstituciones() {
      const response = await this.services.Usuarios.getInstituciones();
      this.instituciones = response.data;
    },
    async getUnidades(id_institucion) {
      const response = await this.services.UnidadesServices.getAllUnidades({
        id_institucion,
      });
      this.unidades = response.data;
    },
    async getTiposCompras() {
      const response =
        await this.services.SolicitudCompra.getTiposSolicitudesCompra();
      if (response.status == 200) {
        this.tipo_compras = response.data;
      }
    },
    async getPasosSolicitudes() {
      const { data, status } =
        await this.services.SolicitudCompra.getCatalogoPasos();

      if (status === 200) {
        this.estados = data;
      }
    },
    async getSolicitudesCompra() {
      const response =
        await this.services.SolicitudCompra.getSolicitudCompraDinac(
          this.filters
        );
      this.solicitudesCompra = response.data.solicitudCompra;
      this.filters.page = Number(response.headers.page);
      this.filters.per_page = Number(response.headers.per_page);
      this.filters.total_rows = Number(response.headers.total_rows);
    },
    checkRange(val) {
      if (this.fechas[0] > this.fechas[1]) {
        this.fechas.splice(0, 0, val);
      }
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getSolicitudesCompra();
    },
    async getSolicitudesCompraFilter() {
      this.filters.id_institucion = this.institucion;
      this.filters.id_unidad = this.unidad;
      this.filters.id_tipo_solicitud = this.tipo_compra;
      this.filters.id_estado = this.estado;
      
      this.filters.fecha_desde = this.fechas[0];
      this.filters.fecha_hasta = this.fechas[1];
      
      this.filters.page = 1;

      const response =
        await this.services.SolicitudCompra.getSolicitudCompraDinac(
          this.filters
        );

      this.solicitudesCompra = response.data.solicitudCompra;
      this.filters.page = Number(response.headers.page);
      this.filters.per_page = Number(response.headers.per_page);
      this.filters.total_rows = Number(response.headers.total_rows);
    },
  },
  async created() {
    await this.getInstituciones();
    await this.getTiposCompras();
    await this.getPasosSolicitudes();
    await this.getSolicitudesCompra();
  },
};
</script>
